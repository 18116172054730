import Auth from "@/components/Auth";
import Home from "@/components/Home";
import VueRouter from "vue-router";
import Vue from "vue";

import Masks from "@/components/Admin/Masks/Masks";
import MaskForm from "@/components/Admin/Masks/MasksForm";
import PersonForm from "@/components/Admin/People/PersonForm";
import People from "@/components/Admin/People/People";
import Users from "@/components/Admin/Users/Users";
import UserForm from "@/components/Admin/Users/UserForm";
import TechnicianExams from "@/components/Technician/Exams/Exams";
import TechnicianExamForm from "@/components/Technician/Exams/ExamForm";
import DoctorExams from "@/components/Doctor/Exams/Exams";
import PatientExams from "@/components/Patient/Exams/Exams";
import MedicalReportForm from "@/components/Doctor/MedicalReports/MedicalReportForm";
import ExamReports from "@/components/Admin/Reports/Exams/Exams";
import ExamPrices from "@/components/Admin/ExamPrices/ExamPrices";
import ExamPricesForm from "@/components/Admin/ExamPrices/ExamPricesForm";
import ExamReferences from "@/components/Admin/ExamReferences/ExamReferences";
import ExamReferencesForm from "@/components/Admin/ExamReferences/ExamReferencesForm";
import AccessLogs from "@/components/AccessLogs";
import PartnershipBranches from "@/components/Admin/PartnershipBranches/PartnershipBranches";
import PartnershipBranchesForm from "@/components/Admin/PartnershipBranches/PartnershipBranchesForm";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    { name: "Home", path: "/", component: Home },
    { name: "auth", path: "/login", component: Auth },
    { name: "AccessLogs", path: "/logs-de-acesso", component: AccessLogs },
    { name: "ExamReports", path: "/relatorios/exames", component: ExamReports },
    { name: "Masks", path: "/mascaras", component: Masks },
    {
      name: "InsertMedicalReport",
      path: "/medico/exames/:examId/laudo/cadastrar",
      component: MedicalReportForm,
      props: { mod: "insert", title: "Cadastrar laudo" },
    },
    {
      name: "UpdateMedicalReport",
      path: "/medico/exames/:examId/laudo/editar",
      component: MedicalReportForm,
      props: { mod: "update", title: "Editar laudo" },
    },
    {
      name: "InsertMasks",
      path: "/mascaras/cadastrar",
      component: MaskForm,
      props: { mod: "insert", title: "Cadastrar máscara" },
    },
    {
      name: "UpdateMasks",
      path: "/mascaras/:id",
      component: MaskForm,
      props: { mod: "update", title: "Atualizar máscara" },
    },
    { name: "People", path: "/pessoas", component: People },
    { name: "Users", path: "/usuarios", component: Users },
    {
      name: "InsertPerson",
      path: "/pessoas/cadastrar",
      component: PersonForm,
      props: { mod: "insert", title: "Cadastrar pessoas" },
    },
    {
      name: "UpdatePerson",
      path: "/pessoas/editar/:id",
      component: PersonForm,
      props: { mod: "update", title: "Editar cadastro de pessoas" },
    },
    {
      name: "InsertUser",
      path: "/usuarios/cadastrar",
      component: UserForm,
      props: { mod: "insert", title: "Cadastrar usuários" },
    },
    {
      name: "UpdateUser",
      path: "/usuarios/editar/:id",
      component: UserForm,
      props: { mod: "update", title: "Editar cadastro de usuários" },
    },
    { name: "Exams", path: "/exames", component: TechnicianExams },
    {
      name: "DoctorExams",
      path: "/medico/exames",
      component: DoctorExams,
    },
    {
      name: "PatientExams",
      path: "/paciente/exames",
      component: PatientExams,
    },
    {
      name: "InsertExam",
      path: "/exames/cadastrar",
      component: TechnicianExamForm,
      props: { mod: "insert", title: "Cadastrar novo exame" },
    },
    {
      name: "UpdateExam",
      path: "/exames/editar/:id",
      component: TechnicianExamForm,
      props: { mod: "update", title: "Editar exame" },
    },
    {
      name: "ExamPrices",
      path: "/precos",
      component: ExamPrices,
    },
    {
      name: "ManageExamPrices",
      path: "/precos/:partnershipId",
      component: ExamPricesForm,
      props: { mod: "update", title: "Configurar preços dos exames" },
    },
    {
      name: "ExamReferences",
      path: "/exames-do-sistema",
      component: ExamReferences,
    },
    {
      name: "InsertExamReferences",
      path: "/exames-do-sistema/cadastrar",
      component: ExamReferencesForm,
      props: { mod: "insert", title: "Cadastrar novo exame" },
    },
    {
      name: "UpdateExamReferences",
      path: "/exames-do-sistema/:id",
      component: ExamReferencesForm,
      props: { mod: "update", title: "Atualizar exame" },
    },
    {
      name: "PartnershipBranches",
      path: "/ramificacoes/:partnershipId",
      component: PartnershipBranches,
    },
    {
      name: "InsertPartnershipBranches",
      path: "/ramificacoes/cadastrar/:partnershipId",
      component: PartnershipBranchesForm,
      props: { mod: "insert", title: "Cadastrar nova ramificação de Empresa/Órgão conveniado" },
    },
    {
      name: "UpdatePartnershipBranches",
      path: "/ramificacoes/:id/parceiro/:partnershipId",
      component: PartnershipBranchesForm,
      props: { mod: "update", title: "Editar ramificação de Empresa/Órgão conveniado" },
    },
  ],
});

export default router;
