<template>
  <div>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title
          >Administrar exames aceitos pelo sistema</v-toolbar-title
        >

        <v-spacer></v-spacer>
        <v-btn small rounded color="success" @click="openInsertScreen">
          Adicionar <v-icon right dark> mdi-plus </v-icon></v-btn
        >
      </v-toolbar>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="items"
          item-key="id"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:top>
            <v-expansion-panels class="mb-5" :value="1">
              <v-expansion-panel>
                <v-expansion-panel-header> Filtros </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-container fluid>
                    <v-row>
                      <v-col>
                        <ExamModeSelect :fromExamForm="false" v-model="filters.ExamModeId" />
                      </v-col>
                      <v-col>
                        <SubspecialtySelect
                          v-model="filters.ExamSubspecialtyId"
                        />
                      </v-col>
                    </v-row>

                    <div class="d-flex justify-end align-center flex-wrap">
                      <v-btn
                        color="success"
                        class="mr-2"
                        @click="loadExamReferences"
                      >
                        <v-icon left>mdi-filter</v-icon> Filtrar</v-btn
                      >
                      <v-btn @click="clearFilters"
                        ><v-icon left>mdi-eraser</v-icon> Limpar</v-btn
                      >
                    </div>
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon title="Editar" @click="openUpdateScreen(item.id)">
              <v-icon> mdi-pencil </v-icon>
            </v-btn>

            <v-btn icon title="Excluir" @click="deleteExamReference(item.id)">
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <div class="text-center mt-2">
      <v-pagination
        v-model="filters.currentPage"
        :length="numberOfPages"
        @input="loadExamReferences"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { baseApiUrl } from "@/global";
import errorHandler from "@/helpers/error_handler";

import ExamModeSelect from "@/components/template/FormElements/Masks/ExamModeSelect";
import SubspecialtySelect from "@/components/template/FormElements/Masks/SubspecialtySelect";

export default {
  components: {
    ExamModeSelect,
    SubspecialtySelect,
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.totalRows / this.resultsPerPage);
    },
  },
  data() {
    return {
      resultsPerPage: 50,
      totalRows: 0,
      loading: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Nome", value: "name" },
        { text: "Modalidade", value: "examMode" },
        { text: "Subspecialidade", value: "examSubspecialty", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
      items: [],
      filters: {
        ExamModeId: null,
        ExamSubspecialtyId: null,
        currentPage: 1,
      },
    };
  },
  methods: {
    async loadExamReferences(page) {
      try {
        this.loading = true;

        let currentPage = page ? page : 1;

        const params = {
          pagination: 1,
          currentPage: currentPage,
          ...this.filters,
        };

        let url = `${baseApiUrl}/exam-references`;

        const response = await axios.get(url, { params });

        this.totalRows = response.data.count ? response.data.count : 0;

        this.setResponseData(response.data.rows);
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    setResponseData(data) {
      const copyOfData = [...data];

      this.items = copyOfData.map((item) => {
        item.examMode = item.ExamMode.name;
        item.examSubspecialty = item.ExamSubspecialty.name;

        return item;
      });

      this.loading = false;
    },
    async deleteExamReference(examReferenceId) {
      try {
        if (
          !(await this.confirmDialog(
            "Deseja realmente deletar o exame selecionado do sistema?"
          ))
        )
          return;

        this.loading = true;

        await axios.delete(`${baseApiUrl}/exam-references/${examReferenceId}`);

        this.deleteExamReferenceInArray(examReferenceId);

        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async confirmDialog(message) {
      return await this.$root.$confirm("Atenção", message, {
        color: "primary",
      });
    },
    deleteExamReferenceInArray(examReferenceId) {
      const index = this.items.findIndex((item) => item.id === examReferenceId);

      if (index !== -1) this.items.splice(index, 1);
    },
    openUpdateScreen(examReferenceId) {
      this.$router.push(`/exames-do-sistema/${examReferenceId}`);
    },
    openInsertScreen() {
      this.$router.push("/exames-do-sistema/cadastrar");
    },
    clearFilters() {
      this.filters = {
        ExamModeId: null,
        ExamSubspecialtyId: null,
        currentPage: 1,
      },
      
      this.loadExamReferences();
    },
  },
  mounted() {
    this.loadExamReferences();
  },
};
</script>

<style>
</style>