<template>
  <div>
    <v-card>
      <v-card-title>Administrar pessoas</v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="items"
          item-key="id"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                clearable
                @click:append="loadPeople()"
                @click:clear="clearFilters()"
                placeholder="Pesquisar pessoas"
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-btn small rounded color="success" @click="openInsertScreen">
                Cadastrar <v-icon right dark> mdi-plus </v-icon></v-btn
              >
            </v-toolbar>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              icon
              title="Editar pessoa"
              @click="openUpdateScreen(item.id)"
            >
              <v-icon> mdi-pencil </v-icon>
            </v-btn>

            <v-btn icon title="Excluir a pessoa" @click="deletePeople(item.id)">
              <v-icon> mdi-delete </v-icon>
            </v-btn>

            <v-btn
              v-if="item.Partnership"
              icon
              title="Ramificações"
              @click="redirectToBranchesPage(item.Partnership.id)"
            >
              <v-icon> mdi-hospital-marker </v-icon>
            </v-btn>

            <v-btn
              v-if="item.Employee"
              icon
              title="Completar dados do colaborador"
              @click="openEmployeeUpdateScreen(item.Employee.id)"
            >
              <v-icon> mdi-account-settings </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <div class="text-center mt-2">
      <v-pagination
        v-model="page"
        :length="numberOfPages"
        @input="loadPeople"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { baseApiUrl } from "@/global";
import errorHandler from "@/helpers/error_handler";

export default {
  components: {},
  data() {
    return {
      resultsPerPage: 15,
      totalRows: 0,
      page: 1,
      search: null,
      loading: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Nome/Nome fantasia", value: "name" },
        { text: "CPF/CNPJ", value: "taxDocument", sortable: false },
        { text: "Vínculo", value: "TypeOfLink.name", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
      items: [],
    };
  },
  methods: {
    openInsertScreen() {
      this.$router.push("/pessoas/cadastrar");
    },
    openUpdateScreen(personId) {
      this.$router.push(`/pessoas/editar/${personId}`);
    },
    deletePersonFromDataTable(personId) {
      const index = this.items.findIndex((item) => item.id == personId);

      if (index != -1) {
        let copyOfItems = [...this.items];

        copyOfItems.splice(index, 1);

        this.items = copyOfItems;
      }
    },
    async deletePeople(personId) {
      try {
        if (
          !(await this.$root.$confirm(
            "Atenção",
            "Deseja realmente deletar a pessoa selecionada?",
            {
              color: "primary",
            }
          ))
        )
          return;

        this.loading = true;

        await axios.delete(`${baseApiUrl}/people/${personId}`);

        this.deletePersonFromDataTable(personId);

        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    clearFilters() {
      this.search = null;

      this.page = 1;

      this.loadPeople();
    },
    setPeopleData(data) {
      const copyOfData = { ...data };

      this.totalRows = copyOfData.count;

      this.items = copyOfData.rows.map((item) => {
        item.name =
          item.type === "physical-person"
            ? `${item.name} ${item.lastName}`
            : item.fantasyName;

        return item;
      });
    },
    async loadPeople(page) {
      try {
        let currentPage = page ? page : 1;
        let url = `${baseApiUrl}/people?pagination=true&resultsPerPage=${this.resultsPerPage}&page=${currentPage}`;

        if (this.search && this.search.length) url += `&search=${this.search}`;

        this.loading = true;

        const response = await axios.get(url);

        this.setPeopleData(response.data);

        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    redirectToBranchesPage(partnershipId) {
      this.$router.push(`/ramificacoes/${partnershipId}`);
    }
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.totalRows / this.resultsPerPage);
    },
  },
  mounted() {
    this.loadPeople();
  },
};
</script>

<style>
</style>
