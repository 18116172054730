<template>
  <BaseForm
    :title="title"
    :mod="mod"
    :flat="flat"
    :divider="divider"
    :dialog="dialog"
    :loading="loading"
    @save="save"
    @close-dialog="$emit('close-dialog')"
  >
    <template #content>
      <v-expansion-panels v-model="panel">
        <v-expansion-panel>
          <v-expansion-panel-header>Dados básicos</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-select
                    class="required"
                    :loading="loadingTypeOfLinks"
                    v-model="form.TypeOfLinkId"
                    :items="typeOfLinks"
                    :readonly="fromCreateExam"
                    label="Tipo de vínculo"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    class="required"
                    v-model="form.type"
                    :items="[
                      { text: 'Pessoa física', value: 'physical-person' },
                      { text: 'Pessoa jurídica', value: 'legal-person' },
                    ]"
                    label="Tipo"
                  ></v-select>
                </v-col>
              </v-row>
              <!-- Dados de pessoa física -->

              <div v-if="form.type === 'physical-person'">
                <v-row>
                  <v-col cols="3">
                    <v-text-field
                      class="required"
                      v-model="form.name"
                      label="Nome:"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                      class="required"
                      v-model="form.lastName"
                      label="Sobrenome:"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      class="required"
                      v-mask="'###.###.###-##'"
                      v-model="form.taxDocument"
                      label="CPF:"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      class="required"
                      v-model="form.gender"
                      :items="[
                        { text: 'Masculino', value: 'male' },
                        { text: 'Feminino', value: 'female' },
                      ]"
                      label="Sexo"
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-text-field
                      class="required"
                      type="date"
                      v-model="form.dateOfBirth"
                      label="Data de nascimento:"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="form.occupation"
                      label="Profissão"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="form.company"
                      label="Empresa onde trabalha:"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <!-- Dados de pessoa jurídica -->
              <div v-else>
                <v-row>
                  <v-col>
                    <v-text-field
                      class="required"
                      v-mask="'##.###.###/####-##'"
                      v-model="form.taxDocument"
                      label="CNPJ:"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      class="required"
                      v-model="form.companyName"
                      label="Razão social:"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      class="required"
                      v-model="form.fantasyName"
                      label="Nome fantasia:"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Contato</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    type="email"
                    v-model="form.email"
                    label="E-Mail:"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-mask="'(##) ####-####'"
                    v-model="form.telephone"
                    label="Telefone:"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-mask="'(##) # ####-####'"
                    v-model="form.cellphone"
                    label="Celular:"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Endereço</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container fluid>
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    v-mask="'#####-###'"
                    v-model="form.zipCode"
                    label="Cep:"
                  ></v-text-field>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="form.address"
                    label="Logradouro:"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <v-text-field
                    v-model="form.addressNumber"
                    label="Número:"
                  ></v-text-field>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="form.district"
                    label="Bairro:"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="form.addressComplement"
                    label="Complemento:"
                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-select
                    v-model="form.CityId"
                    label="Cidade:"
                    placeholder="Selecione um estado"
                    :items="cities"
                  ></v-select>
                </v-col>
                <v-col cols="3">
                  <v-select
                    :items="states"
                    label="Estado:"
                    @change="loadCities"
                    v-model="form.stateUf"
                  ></v-select>
                </v-col>
                <v-col> </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Dados adicionais</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col>
                <v-textarea
                  label="Observações"
                  hint="Deixe aqui alguma observação que você queira fazer sobre a pessoa que está sendo cadastrada."
                  v-model="form.observations"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </BaseForm>
</template>

<script>
import axios from "axios";
import { baseApiUrl } from "@/global";
import { mask } from "vue-the-mask";
import errorHandler from "@/helpers/error_handler";
import formMixin from "@/mixins/formMixin";
import BaseForm from "@/components/template/BaseForm";

export default {
  directives: { mask },
  mixins: [formMixin],
  components: { BaseForm },
  props: {
    fromCreateExam: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      loadingCities: false,
      loadingStates: false,
      loadingTypeOfLinks: false,
      states: [],
      cities: [],
      typeOfLinks: [],
      panel: 0,
      form: {
        type: "physical-person",
      },
    };
  },
  methods: {
    prepareDataToSend(data) {
      const copyOfData = { ...data };

      return copyOfData;
    },
    extractIdsFromSelectedCompanyLinks(links) {
      const ids = links.map((l) => l.value);

      return ids;
    },

    async loadTypesOfLinks() {
      try {
        this.loadingTypeOfLinks = true;
        const response = await axios.get(`${baseApiUrl}/type-of-links`);

        this.typeOfLinks = response.data.map((t) => {
          return {
            text: t.name,
            value: t.id,
          };
        });
        this.loadingTypeOfLinks = false;
      } catch (error) {
        this.loadingTypeOfLinks = false;
      }
    },
    async loadCities(selectedState) {
      try {
        if (!selectedState) return;

        this.loadingCities = true;
        const response = await axios.get(
          `${baseApiUrl}/cities?uf=${selectedState}`
        );

        this.cities = response.data.map((c) => {
          return {
            text: c.name,
            value: c.id,
          };
        });
        this.loadingCities = false;
      } catch (error) {
        this.loadingCities = false;
      }
    },
    async loadStates() {
      try {
        this.loadingStates = true;
        const response = await axios.get(`${baseApiUrl}/states`);

        this.states = response.data.map((s) => {
          return {
            text: s.name,
            value: s.uf,
          };
        });
        this.loadingStates = false;
      } catch (error) {
        this.loadingStates = false;
      }
    },
    async setPersonData(data) {
      const copyOfData = data;

      for (const property in copyOfData)
        if (!copyOfData[property]) delete copyOfData[property];

      if (copyOfData.stateUf) await this.loadCities(copyOfData.stateUf);

      Object.assign(this.form, copyOfData);
    },
    async loadPersonData() {
      try {
        this.loading = true;

        const response = await axios.get(
          `${baseApiUrl}/people/${this.personId}`
        );

        await this.setPersonData(response.data);

        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async update(actionAfterUpdate) {
      try {
        this.loading = true;

        const dataToSend = this.prepareDataToSend(this.form);

        await axios.put(`${baseApiUrl}/people/${this.personId}`, dataToSend);

        this.loading = false;

        this.afterUpdate(actionAfterUpdate);
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async insert(actionAfterInsertion) {
      try {
        this.loading = true;

        const dataToSend = this.prepareDataToSend(this.form);

        const response = await axios.post(`${baseApiUrl}/people`, dataToSend);

        this.loading = false;

        this.afterInsertion(
          actionAfterInsertion,
          { ...this.form, ...response.data },
          {
            type: "physical-person",
          }
        );
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    validateForm() {
      const errors = [];

      if (!this.form.TypeOfLinkId)
        errors.push("O campo TIPO DE VÍNCULO é OBRIGATÓRIO");

      if (!this.form.type)
        errors.push("O campo TIPO DE PESSOA é OBRIGATÓRIO: Fisíca/Jurídica");

      if (this.form.type === "physical-person") {
        if (!this.form.name) errors.push("O campo NOME é OBRIGATÓRIO");
        if (!this.form.lastName) errors.push("O campo SOBRENOME é OBRIGATÓRIO");
        if (!this.form.taxDocument) errors.push("O campo CPF é OBRIGATÓRIO");
        if (!this.form.dateOfBirth)
          errors.push("O campo DATA DE NASCIMENTO é OBRIGATÓRIO");
        if (!this.form.gender) errors.push("O campo SEXO é OBRIGATÓRIO");
      } else if (this.form.type === "legal-person") {
        if (!this.form.companyName)
          errors.push("O campo RAZÃO SOCIAL é OBRIGATÓRIO");
        if (!this.form.fantasyName)
          errors.push("O campo NOME FANTASIA é OBRIGATÓRIO");
        if (!this.form.taxDocument) errors.push("O campo CNPJ é OBRIGATÓRIO");
      }

      return errors;
    },
    save(actionAfterOperation) {
      const errors = this.validateForm();

      if (errors.length) {
        this.$root.$errorDialog(errors, {
          width: "800",
          color: "primary",
        });
      } else {
        this[this.mod](actionAfterOperation);
      }
    },
  },
  computed: {
    personId() {
      return this.$props.dialog ? this.databaseId : this.$route.params.id;
    },
  },
  created() {
    if (this.$props.mod === "update") this.loadPersonData();

    if (this.fromCreateExam) this.form.TypeOfLinkId = 1;

    this.loadTypesOfLinks();
    this.loadStates();
  },
};
</script>

<style>
</style>
