<template>
  <BaseForm
    :title="title"
    :mod="mod"
    :flat="flat"
    :divider="divider"
    :dialog="dialog"
    :loading="loading"
    @save="save"
  >
    <template #content>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="form.PatientId"
              :items="patients"
              :loading="loadingAutocomplete"
              :search-input.sync="search"
              placeholder="Digite o nome do paciente"
              label="Paciente:"
              return-object
              chips
              class="required"
              deletable-chips
            >
              <template v-slot:no-data>
                <v-btn text small @click.stop="insertionDialog = true">
                  Cadastrar paciente
                  <v-icon right color="blue"
                    >mdi-plus-circle-outline</v-icon
                  ></v-btn
                >
              </template>

              <template v-if="patients.length" v-slot:append-item>
                <v-btn text small @click.stop="insertionDialog = true">
                  Cadastrar paciente
                  <v-icon right color="blue"
                    >mdi-plus-circle-outline</v-icon
                  ></v-btn
                >
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <ExamModeSelect
              class="required"
              :fromExamForm="true"
              v-model="form.examMode"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <ExamPartnershipsSelect v-model="partnershipId" class="required" />
          </v-col>
        </v-row>

        <v-row v-if="partnershipId">
          <v-col>
            <ExamBranchSelect
              :partnershipId="partnershipId"
              v-model="form.PartnershipBranchId"
              class="required"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              label="Data do exame"
              v-model="form.examDate"
              type="date"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-mask="'##:##:##'"
              v-model="form.examHour"
              label="Hora do exame:"
              append-icon="mdi-clock-outline"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="form.clinicalData"
              label="Dados clínicos:"
              class="required"
              counter="254"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="form.requestedBy"
              label="Solicitado por:"
              placeholder="Nome do médico que solicitou o exame"
              class="required"
              counter="254"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="mod === 'update'">
          <v-col>
            <h4>Imagens do exame:</h4>
          </v-col>
        </v-row>

        <v-row class="mt-2" v-if="mod === 'update'">
          <v-col v-bind:key="img.id" v-for="img in form.srcFiles">
            <v-row>
              <v-col>
                {{ img.src.split("-")[1] }}
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-img :src="img.src" max-width="400"></v-img>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row v-if="form.srcFiles && form.srcFiles.length">
          <v-col>
            <v-btn
              class="ma-2"
              small
              rounded
              color="primary"
              @click="deleteArchives()"
            >
              <v-icon left dark> mdi-close </v-icon> Remover arquivos
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <ExamUrgence v-model="form.UrgentExamStatusId" class="required" />
          </v-col>
          <v-col cols="12" md="6">
            <v-file-input
              v-model="files"
              ref="files"
              show-size
              label="Adicionar arquivo (s):"
              multiple
            ></v-file-input>
          </v-col>
        </v-row>
      </v-container>

      <v-dialog v-model="insertionDialog">
        <PersonForm
          :mod="'insert'"
          :modalTitle="'Cadastrar paciente'"
          :fromCreateExam="true"
          dialog
          divider
          @close-dialog="insertionDialog = false"
          @insert-completed="setPatientAdded"
        />
      </v-dialog>
    </template>

    <template #actions></template>
  </BaseForm>
</template>

<script>
import axios from "axios";
import { baseApiUrl } from "@/global";
import formMixin from "@/mixins/formMixin";
import errorHandler from "@/helpers/error_handler";
import { mask } from "vue-the-mask";

import BaseForm from "@/components/template/BaseForm";
import PersonForm from "@/components/Admin/People/PersonForm";

import ExamPartnershipsSelect from "@/components/template/FormElements/Exams/ExamPartnershipsSelect";
import ExamBranchSelect from "@/components/template/FormElements/Exams/ExamBranchSelect";
import ExamUrgence from "@/components/template/FormElements/Exams/ExamUrgence";
import ExamModeSelect from "@/components/template/FormElements/Masks/ExamModeSelect";

export default {
  components: {
    BaseForm,
    PersonForm,
    ExamBranchSelect,
    ExamUrgence,
    ExamPartnershipsSelect,
    ExamModeSelect,
  },
  mixins: [formMixin],
  directives: { mask },
  computed: {
    examId() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      loading: false,
      loadingAutocomplete: false,
      insertionDialog: false,
      search: null,
      partnershipId: null,
      patient: {},
      form: {},
      files: [],
      patients: [],
    };
  },
  methods: {
    save(actionAfterOperation) {
      const errors = this.validateForm();

      if (errors.length) {
        this.$root.$errorDialog(errors, {
          width: "800",
          color: "primary",
        });
      } else {
        this[this.mod](actionAfterOperation);
      }
    },
    async insert(actionAfterOperation) {
      try {
        this.loading = true;

        let formData = new FormData();

        for (let i = 0; i < this.files.length; i++) {
          formData.append("file", this.files[i]);
        }

        let dataToSend = this.formatDataToSend(this.form);

        for (const key of Object.keys(dataToSend)) {
          formData.append(key, dataToSend[key]);
        }

        const response = await axios.post(`${baseApiUrl}/exams`, formData);

        this.loading = false;

        this.files = [];

        this.afterInsertion(actionAfterOperation, {
          ...this.form,
          id: response.data.id,
        });
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async update(actionAfterUpdate) {
      try {
        this.loading = true;

        let formData = new FormData();

        for (let i = 0; i < this.files.length; i++) {
          formData.append("file", this.files[i]);
        }

        let dataToSend = this.formatDataToSend(this.form);

        for (const key of Object.keys(dataToSend)) {
          formData.append(key, dataToSend[key]);
        }

        const response = await axios.put(
          `${baseApiUrl}/exams/${this.examId}`,
          formData
        );

        this.addUploadedImages(response.data);

        this.files = [];

        this.afterUpdate(actionAfterUpdate);

        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async loadExamData() {
      try {
        this.loading = true;

        const response = await axios.get(`${baseApiUrl}/exams/${this.examId}`);

        this.setExamData(response.data);

        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    addUploadedImages(examFiles) {
      const copyOfExamFiles = [...examFiles];

      //adiciona as imagens
      for (let i = 0; i < copyOfExamFiles.length; i++) {
        this.form.srcFiles.push({
          id: copyOfExamFiles[i].id,
          src: `${baseApiUrl}/uploads/${copyOfExamFiles[i].fileName}`,
        });
      }
    },
    setExamData(data) {
      const copyOfData = { ...data, srcFiles: [] };

      let examFiles = data.ExamFiles;

      //adiciona as imagens
      for (let i = 0; i < examFiles.length; i++) {
        copyOfData.srcFiles.push({
          id: examFiles[i].id,
          src: `${baseApiUrl}/uploads/${examFiles[i].fileName}`,
        });
      }

      //fotmata data e hora
      [
        copyOfData.examDate,
        copyOfData.examHour,
      ] = copyOfData.dateOfRealization.split("T");

      copyOfData.examHour = copyOfData.examHour.split(".")[0];

      //adiciona campo do partnsership

      this.partnershipId = copyOfData.PartnershipBranch.PartnershipId;

      //formata dados do paciente
      const patientObj = {
        text: `${copyOfData.Patient.Person.name} ${copyOfData.Patient.Person.lastName}`,
        value: copyOfData.Patient.id,
      };

      this.patients.push(patientObj);
      copyOfData.PatientId = patientObj;

      this.form = copyOfData;
    },
    formatDataToSend(data) {
      let dataCopy = { ...data };

      dataCopy.PatientId = dataCopy.PatientId.value;

      dataCopy.dateOfRealization = `${dataCopy.examDate} ${dataCopy.examHour}`;

      return dataCopy;
    },
    validateForm() {
      const errors = [];

      if (!this.form.examDate || !this.form.examHour)
        errors.push("Data e hora do exame obrigatórios!");

      if (this.mod === "insert" && this.files.length == 0)
        errors.push("Informe pelo menos um arquivo!");

      if (!this.form.PatientId) errors.push("Infome o paciente!");

      if (!this.form.clinicalData) errors.push("Dados clínicos obrigatórios!");

      if (!this.form.requestedBy)
        errors.push("Informe o médico que solicitou o exame!");

      if (!this.partnershipId)
        errors.push("Empresa/Órgão conveniado obrigatório!");

      if (!this.form.examMode) errors.push("Campo 'Modalidade' obrigatório!");

      if (!this.form.PartnershipBranchId) errors.push("Local obrigatório!");

      if (!this.form.UrgentExamStatusId)
        errors.push("Defina uma urgência para o exame!");

      if (
        this.mod === "update" &&
        !this.form.srcFiles.length &&
        this.files.length == 0
      )
        errors.push("Anexe os arquivos para o exame!");

      return errors;
    },
    async confirmDialog(message) {
      return await this.$root.$confirm("Atenção", message, {
        color: "primary",
      });
    },
    async deleteArchives() {
      try {
        if (
          !(await this.confirmDialog(
            "Deseja realmente deletar os arquivos do sistema?"
          ))
        )
          return;

        this.loading = true;

        await axios.delete(`${baseApiUrl}/exams/${this.examId}/files`);

        this.form.srcFiles = [];

        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    setPatientAdded(data) {
      const obj = {
        value: data.PatientId,
        text: `${data.name} ${data.lastName}`,
      };

      this.patients.push(obj);

      this.form.PatientId = obj;

      this.insertionDialog = false;
    },
    setPatients(data) {
      const copyOfData = [...data];

      this.patients = copyOfData.map((d) => {
        return { value: d.Patient.id, text: `${d.name} ${d.lastName}` };
      });
    },
    async searchPatients(search) {
      try {
        this.loadingAutocomplete = true;

        const response = await axios.get(
          `${baseApiUrl}/people?search=${search}&pagination=false&patients=true`
        );

        this.setPatients(response.data);

        this.loadingAutocomplete = false;
      } catch (error) {
        this.loadingAutocomplete = false;

        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
  },
  watch: {
    search(val) {
      if (this.loading || !val) return;

      this.searchPatients(val);
    },
  },
  created() {
    if (this.mod === "update") this.loadExamData();
  },
};
</script>

<style>
.label-mask {
  font-size: 1.05rem;
}
</style>