<template>
  <div>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>Configurar preços dos exames</v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="items"
          item-key="id"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon title="Editar" @click="openUpdateScreen(item.id)">
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <div class="text-center mt-2">
      <v-pagination
        v-model="filters.currentPage"
        :length="numberOfPages"
        @input="loadPartnerships"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { baseApiUrl } from "@/global";
import errorHandler from "@/helpers/error_handler";

export default {
  computed: {
    numberOfPages() {
      return Math.ceil(this.totalRows / this.resultsPerPage);
    },
  },
  data() {
    return {
      resultsPerPage: 50,
      totalRows: 0,
      loading: false,
      headers: [
        { text: "ID", value: "PersonId" },
        { text: "Empresa/Órgão conveniado", value: "partershipName" },
        { text: "Ações", value: "actions", sortable: false },
      ],
      items: [],
      filters: {
        currentPage: 1,
      },
    };
  },
  methods: {
    async loadPartnerships(page) {
      try {
        this.loading = true;

        let currentPage = page ? page : 1;

        let url = `${baseApiUrl}/partnerships`;

        const response = await axios.get(url, {
          params: {
            pagination: 1,
            currentPage: currentPage,
          },
        });

        this.totalRows = response.data.count ? response.data.count : 0;

        this.setResponseData(response.data.rows);
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    setResponseData(data) {
      const copyOfData = [...data];

      this.items = copyOfData.map((item) => {
        item.partershipName = item.Person.fantasyName;

        return item;
      });

      this.loading = false;
    },
    openUpdateScreen(partnershipId) {
      console.log(partnershipId);
      this.$router.push(`/precos/${partnershipId}`);
    },
  },
  mounted() {
    this.loadPartnerships();
  },
};
</script>

<style>
</style>