<template>
  <v-list>
    <v-list-item class="px-2">
      <v-list-item-avatar>
        <Gravatar email="user@email.com.br" alt="user" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title> {{ userName }} </v-list-item-title>
        <v-list-item-subtitle> {{ accessProfileName }} </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn small icon @click="logout">
          <v-icon>mdi-logout-variant</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>

    <div
      v-bind:key="node.text"
      v-for="node in accessProfileMenu[`${userData.accessProfile.id}`]"
    >
      <v-list-group v-model="node.data.expanded" v-if="node.children && node.children.length">
        <template v-slot:activator>
          <v-list-item-action>
            <v-icon>{{ node.data.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="node.text"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-bind:key="child.text"
          v-for="child in node.children"
          @click="onNodeSelect(child)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ child.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-item v-else @click="onNodeSelect(node)">
        <v-list-item-icon>
          <v-icon>{{ node.data.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-title>{{ node.text }}</v-list-item-title>
      </v-list-item>
    </div>
  </v-list>
</template>

<script>
import Gravatar from "vue-gravatar";
import { mapState, mapActions } from "vuex";
import { userKey } from "@/global";
export default {
  components: {
    Gravatar,
  },
  data() {
    return {
      accessProfileMenu: {
        1: [
          {
            text: "Página inicial",
            data: {
              route: "Home",
              icon: "mdi-home",
              isVisible: true,
              expanded: false,
            },
          },
          {
            text: "Logs de acesso",
            data: {
              route: "AccessLogs",
              icon: "mdi-account-reactivate",
              isVisible: true,
              expanded: false,
            },
          },
          {
            text: "Relatórios",
            data: {
              route: "ExamReports",
              icon: "mdi-chart-box",
              isVisible: true,
              expanded: false,
            },
          },
          {
            text: "Exames",
            key: "exams",
            data: {
              route: "DoctorExams",
              icon: "mdi-file-document",
              isVisible: true,
              expanded: false,
            },
          },
          {
            text: "Cadastros",
            data: {
              icon: "mdi-clipboard-text",
              isVisible: true,
              expanded: false,
            },
            children: [
              {
                text: "Preços",
                data: {
                  route: "ExamPrices",
                  icon: "mdi-cash-usd",
                  isVisible: true,
                  expanded: false,
                },
              },
              {
                text: "Máscaras",
                data: {
                  route: "Masks",
                  icon: "",
                  isVisible: true,
                  expanded: false,
                },
              },
              {
                text: "Pessoas",
                data: {
                  route: "People",
                  icon: "",
                  isVisible: true,
                  expanded: false,
                },
              },
              {
                text: "Usuários",
                data: {
                  route: "Users",
                  icon: "",
                  isVisible: true,
                  expanded: false,
                },
              },
              {
                text: "Exames",
                data: {
                  route: "ExamReferences",
                  icon: "",
                  isVisible: true,
                  expanded: false,
                },
              },
            ],
          },
        ],
        2: [
          {
            text: "Página inicial",
            data: {
              route: "Home",
              icon: "mdi-home",
              isVisible: true,
              expanded: false,
            },
          },
          {
            text: "Logs de acesso",
            data: {
              route: "AccessLogs",
              icon: "mdi-account-reactivate",
              isVisible: true,
              expanded: false,
            },
          },
          {
            text: "Exames",
            data: {
              route: "Exams",
              icon: "mdi-file-document",
              isVisible: true,
              expanded: false,
            },
          },
        ],
        3: [
          {
            text: "Logs de acesso",
            data: {
              route: "AccessLogs",
              icon: "mdi-account-reactivate",
              isVisible: true,
              expanded: false,
            },
          },
          {
            text: "Meus exames",
            key: "exams",
            data: {
              route: "PatientExams",
              icon: "mdi-file-document",
              isVisible: true,
              expanded: false,
            },
          },
        ],
        4: [
          {
            text: "Página inicial",
            data: {
              route: "Home",
              icon: "mdi-home",
              isVisible: true,
              expanded: false,
            },
          },
          {
            text: "Logs de acesso",
            data: {
              route: "AccessLogs",
              icon: "mdi-account-reactivate",
              isVisible: true,
              expanded: false,
            },
          },
          {
            text: "Exames",
            key: "exams",
            data: {
              route: "DoctorExams",
              icon: "mdi-file-document",
              isVisible: true,
              expanded: false,
            },
          },
        ],
      },
    };
  },
  methods: {
    ...mapActions("user", ["setUserData"]),
    logout() {
      this.setUserData(null);
      localStorage.removeItem(userKey);
      window.location = "/";
    },
    onNodeSelect(node) {
      if (node.data.route) {
        this.$router.push({
          name: node.data.route,
          params: node.data,
        });
      }
    },
  },
  computed: {
    ...mapState("user", {
      userData: (state) => state.data,
    }),
    userName() {
      return `${this.userData.name} ${this.userData.lastName}`;
    },
    accessProfileName() {
      return this.userData.accessProfile.name;
    },
    menuItems() {
      const items = [];

      for (const item of this.treeData) {
        if (item.children.length) {
          let children = item.children.filter(
            (c) => this.accessProfileRoutes.indexOf(c.route) !== -1
          );

          if (children.length) items.push(item);
        } else {
          if (this.accessProfileRoutes.indexOf(item.route) !== -1)
            items.push(item);
        }
      }

      return items;
    },
  },
};
</script>

<style>
</style>