<template>
  <BaseForm
    :title="title"
    :mod="mod"
    :flat="flat"
    :divider="divider"
    :dialog="dialog"
    :loading="loading"
    @save="save"
  >
    <template #content>
      <v-container fluid>

        <v-row>
          <v-col>
            <v-text-field
              class="required"
              v-model="form.name"
              label="Nome:"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <ExamModeSelect
              v-model="form.ExamModeId"
              class="required"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <SubspecialtySelect
              v-model="form.ExamSubspecialtyId"
              class="required"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template #actions></template>
  </BaseForm>
</template>

<script>
import axios from "axios";
import { baseApiUrl } from "@/global";
import formMixin from "@/mixins/formMixin";
import errorHandler from "@/helpers/error_handler";

import BaseForm from "@/components/template/BaseForm";

import ExamModeSelect from "@/components/template/FormElements/Masks/ExamModeSelect";
import SubspecialtySelect from "@/components/template/FormElements/Masks/SubspecialtySelect";

export default {
  components: {
    BaseForm,
    ExamModeSelect,
    SubspecialtySelect,
  },
  mixins: [formMixin],
  directives: {},
  computed: {
    examReferenceId() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      loading: false,
      cleanMask: false,
      examModeOption: {},
      form: {},
    };
  },
  methods: {
    save(actionAfterOperation) {
      const errors = this.validateForm();

      if (errors.length) {
        errors.forEach((e) => {
          console.log(`${e}`);
        });
      } else {
        this[this.mod](actionAfterOperation);
      }
    },
    async insert(actionAfterOperation) {
      try {
        this.loading = true;

        const response = await axios.post(
          `${baseApiUrl}/exam-references`,
          this.form
        );

        this.loading = false;

        console.log("Operação concluída com sucesso");

        this.cleanMask = true;

        this.afterInsertion(actionAfterOperation, {
          ...this.form,
          id: response.data.id,
        });
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async update(actionAfterUpdate) {
      try {
        this.loading = true;

        await axios.put(
          `${baseApiUrl}/exam-references/${this.examReferenceId}`,
          this.form
        );
        this.loading = false;

        this.afterUpdate(actionAfterUpdate);
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async loadExamReferenceData() {
      try {
        this.loading = true;

        const response = await axios.get(
          `${baseApiUrl}/exam-references/${this.examReferenceId}`
        );

        this.setExamReferenceData(response.data);

        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    setExamReferenceData(data) {
      this.form = { ...data };

      this.loading = false;
    },
    validateForm() {
      const errors = [];

      if (!this.form.ExamModeId) errors.push("Campo modalidade obrigatório!");

      if (!this.form.name) errors.push("Nome obrigatório!");

      if (!this.form.ExamSubspecialtyId)
        errors.push("Campo subespecialidade obrigatória!");

      return errors;
    },
  },
  created() {
    if (this.mod === "update") this.loadExamReferenceData();
  },
};
</script>

<style>
.label-mask {
  font-size: 1.05rem;
}
</style>