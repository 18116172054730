<template>
  <v-app>
    <v-navigation-drawer v-if="userDataInMemory" v-model="drawer" app>
      <Menu />
    </v-navigation-drawer>

    <v-app-bar v-if="userDataInMemory" app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Multimagem R.A</v-toolbar-title>
    </v-app-bar>
    <Content />
    <ConfirmDialog ref="confirmDialog"></ConfirmDialog>
    <ErrorDialog ref="errorDialog"></ErrorDialog>
  </v-app>
</template>

<script>
import Content from "@/components/template/Content";
import Menu from "@/components/template/Menu";
import ConfirmDialog from "@/components/template/ConfirmDialog";
import ErrorDialog from "@/components/template/ErrorDialog";
import { mapState, mapActions } from "vuex";
import { userKey } from "@/global";

export default {
  components: {
    Content,
    Menu,
    ConfirmDialog,
    ErrorDialog,
  },
  data() {
    return {
      drawer: true,
    };
  },
  methods: {
    ...mapActions("user", ["setUserData"]),
    checkUserLogged() {
      if (!this.userDataInMemory) {
        const userDataInLocalStorage = JSON.parse(
          localStorage.getItem(userKey)
        );

        if (userDataInLocalStorage) {
          this.setUserData(userDataInLocalStorage);
        } else {
          this.$router.push({ name: "auth" });
        }
      }
    },
  },
  computed: {
    ...mapState("user", {
      userDataInMemory: (state) => state.data,
    }),
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirmDialog.open;
    this.$root.$errorDialog = this.$refs.errorDialog.open;
  },
  created() {
    this.checkUserLogged();
  },
};
</script>


<style>
</style>