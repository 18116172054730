<template>
  <div>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title
          >Ramificações das Empresas/Órgãos conveniados</v-toolbar-title
        >

        <v-spacer></v-spacer>
        <v-btn small rounded color="success" @click="openInsertScreen">
          Cadastrar <v-icon right dark> mdi-plus </v-icon></v-btn
        >
      </v-toolbar>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="items"
          item-key="id"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:top>
            <v-expansion-panels class="mb-5" :value="1">
              <v-expansion-panel>
                <v-expansion-panel-header> Filtros </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-container fluid>
                    <v-row
                      ><v-col>
                        <v-text-field
                          label="Nome"
                          placeholder="Pesquise pelo nome"
                          v-model="filters.search"
                        >
                        </v-text-field
                      ></v-col>
                    </v-row>

                    <div class="d-flex justify-end align-center flex-wrap">
                      <v-btn color="success" class="mr-2" @click="loadPartnershipBranches">
                        <v-icon left>mdi-filter</v-icon> Filtrar</v-btn
                      >
                      <v-btn @click="clearFilters"
                        ><v-icon left>mdi-eraser</v-icon> Limpar</v-btn
                      >
                    </div>
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon title="Editar" @click="openUpdateScreen(item.id)">
              <v-icon> mdi-pencil </v-icon>
            </v-btn>

            <v-btn icon title="Excluir" @click="deletePartnershipBranch(item.id)">
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <div class="text-center mt-2">
      <v-pagination
        v-model="filters.currentPage"
        :length="numberOfPages"
        @input="loadPartnershipBranches"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { baseApiUrl } from "@/global";
import errorHandler from "@/helpers/error_handler";

export default {
  computed: {
    numberOfPages() {
      return Math.ceil(this.totalRows / this.resultsPerPage);
    },
  },
  data() {
    return {
      resultsPerPage: 50,
      totalRows: 0,
      loading: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Nome", value: "name" },
        { text: "Ações", value: "actions", sortable: false },
      ],
      items: [],
      filters: {
        search: null,
        currentPage: 1,
      },
    };
  },
  methods: {
    async loadPartnershipBranches(page) {
      try {
        this.loading = true;

        let currentPage = page ? page : 1;

        let url = `${baseApiUrl}/partnership-branches/partnerships/${this.$route.params.partnershipId}}`;

        const response = await axios.get(url, {
          params: {
            pagination: 1,
            search: this.filters.search,
            currentPage: currentPage,
          },
        });

        this.totalRows = response.data.count ? response.data.count : 0;

        this.setResponseData(response.data.rows);
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    setResponseData(data) {
      const copyOfData = [...data];

      // this.items = copyOfData.map((item) => {
      //   item.partershipName = item.Person.fantasyName;

      //   return item;
      // });

      this.items = copyOfData;

      this.loading = false;
    },
    openUpdateScreen(partnershipBrancheId) {
      this.$router.push(`/ramificacoes/${partnershipBrancheId}/parceiro/${this.$route.params.partnershipId}`);
    },
    openInsertScreen() {
      this.$router.push(`/ramificacoes/cadastrar/${this.$route.params.partnershipId}`);
    },
    clearFilters() {
      this.filters = {
        search: null,
        currentPage: 1,
      };

      this.loadPartnershipBranches();
    },
    async deletePartnershipBranch(partnershipBranchId){
      try {
        if (
          !(await this.confirmDialog(
            "Deseja realmente deletar o item selecionado do sistema?"
          ))
        )
          return;

        this.loading = true;

        await axios.delete(`${baseApiUrl}/exam-references/${partnershipBranchId}`);

        this.deleteLocally(partnershipBranchId);

        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    deleteLocally(partnershipBranchId) {
      const index = this.items.findIndex((item) => item.id === partnershipBranchId);

      if (index !== -1) this.items.splice(index, 1);
    },
    async confirmDialog(message) {
      return await this.$root.$confirm("Atenção", message, {
        color: "primary",
      });
    },
  },
  mounted() {
    this.loadPartnershipBranches();
  },
};
</script>

<style>
</style>