<template>
  <BaseForm
    :title="title"
    :mod="mod"
    :flat="flat"
    :divider="divider"
    :dialog="dialog"
    :loading="loading"
    @save="save"
  >
    <template #content>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-model="form.PersonId"
              :items="people"
              :loading="loadingPeople"
              :search-input.sync="search"
              :readonly="mod === 'update'"
              chips
              deletable-chips
              label="Selecionar pessoa"
              placeholder="Comece a digitar para procurar por nome"
              return-object
            >
              <template v-slot:no-data>
                <v-btn text small @click="openPersonInsertionDialog">
                  Cadastrar pessoa
                  <v-icon right color="blue"
                    >mdi-plus-circle-outline</v-icon
                  ></v-btn
                >
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row
          ><v-col>
            <v-select
              label="Perfil de acesso"
              :items="accessProfiles"
              v-model="form.AccessProfileId"
              :loading="loadingAccessProfiles"
            >
            </v-select></v-col
        ></v-row>

        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.password"
              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              :type="showPassword ? 'text' : 'password'"
              name="input-10-2"
              :label="mod === 'update' ? 'Nova senha' : 'Senha'"
              class="input-group--focused"
              @click:append="showPassword = !showPassword"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.confirmPassword"
              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              :type="showPassword ? 'text' : 'password'"
              name="input-10-3"
              :label="
                mod === 'update' ? 'Confirmar nova senha' : 'Confirmar senha'
              "
              class="input-group--focused"
              @click:append="showPassword = !showPassword"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-dialog v-model="personInsertionDialog" width="800">
          <PersonForm
            mod="insert"
            dialog
            divider
            @close-dialog="personInsertionDialog = false"
            @insert-completed="insertCompleted"
          />
        </v-dialog>
      </v-container>
    </template>
  </BaseForm>
</template>

<script>
import { baseApiUrl } from "@/global";
import axios from "axios";
import PersonForm from "@/components/Admin/People/PersonForm";
import BaseForm from "@/components/template/BaseForm";
import errorHandler from "@/helpers/error_handler";
import formMixin from "@/mixins/formMixin";

export default {
  mixins: [formMixin],
  components: { PersonForm, BaseForm },
  data: function () {
    return {
      showPassword: false,
      loading: false,
      loadingAccessProfiles: false,
      loadingPeople: false,
      accessProfiles: [],
      personInsertionDialog: false,
      search: null,
      people: [],
      form: {},
    };
  },
  watch: {
    search(value) {
      if (!value) return;

      this.searchPeople(value);
    },
    accessProfileId(value) {
      this.haveCustomPermissions = !value ? true : false;
    },
  },
  methods: {
    insertCompleted(person) {
      const newPerson = {
        value: person.id,
        text: `${person.name} ${person.lastName}`,
      };

      this.people.push(newPerson);

      this.form.PersonId = newPerson;

      this.personInsertionDialog = false;
    },
    openPersonInsertionDialog() {
      this.personInsertionDialog = true;
    },
    async searchPeople(search) {
      try {
        this.loadingPeople = true;

        const res = await axios.get(`${baseApiUrl}/people`, {
          params: { search },
        });

        this.setPeople(res.data);

        this.loadingPeople = false;
      } catch (error) {
        this.loadingPeople = false;
      }
    },
    setPeople(people) {
      this.people = people.map((p) => {
        return { value: p.id, text: `${p.name} ${p.lastName}` };
      });
    },
    async loadAccessProfiles() {
      try {
        this.loadingAccessProfiles = true;

        const url = `${baseApiUrl}/access-profiles`;

        this.accessProfiles = [];

        const response = await axios.get(url);

        this.accessProfiles = response.data.map((p) => {
          return { value: p.id, text: p.name };
        });

        this.loadingAccessProfiles = false;
      } catch (error) {
        this.loadingAccessProfiles = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async confirmModal(title, msg, options) {
      return await this.$root.$confirm(title, msg, options);
    },
    save(actionAfterOperation) {
      const errors = this.validateForm();

      if (errors.length) {
        this.$root.$errorDialog(errors, {
          width: "800",
          color: "primary",
        });
      } else {
        this[this.mod](actionAfterOperation);
      }
    },
    prepareDataToInsert(data) {
      const copyOfData = { ...data };

      copyOfData.PersonId = copyOfData.PersonId.value;
      return copyOfData;
    },
    async insert(actionAfterInsertion) {
      try {
        this.loading = true;

        const url = `${baseApiUrl}/users`;

        const dataToInsert = this.prepareDataToInsert(this.form);

        const response = await axios.post(url, dataToInsert);

        this.loading = false;

        this.afterInsertion(
          actionAfterInsertion,
          { ...this.form, ...response.data },
          {}
        );
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    validateForm() {
      const errors = [];

      if (!this.form.PersonId) errors.push("Vincule o usuário a uma pessoa!");

      if (!this.form.AccessProfileId)
        errors.push("Perfil de acesso não selecionado");

      if (this.mod === "insert") {
        if (this.form.password === "" || !this.form.password)
          errors.push("Senha obrigatória!");

        if (this.form.password !== this.form.confirmPassword)
          errors.push("confirmação de senha inválida!");
      } else if (this.mod === "update") {
        if (this.form.password && this.form.password.length) {
          if (this.form.password !== this.form.confirmPassword)
            errors.push("confirmação de senha inválida!");
        }
      }

      return errors;
    },
    prepareDataToUpdate(data) {
      const copyOfData = { ...data };

      delete copyOfData.PersonId;
      delete copyOfData.id;

      return copyOfData;
    },
    async update(actionAfterUpdate) {
      try {
        this.loading = true;

        const url = `${baseApiUrl}/users/${this.userId}`;

        const dataToUpdate = this.prepareDataToUpdate(this.form);

        await axios.put(url, dataToUpdate);

        this.loading = false;

        this.afterUpdate(actionAfterUpdate);
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async loadUserData() {
      try {
        this.loading = true;

        const response = await axios.get(`${baseApiUrl}/users/${this.userId}`);

        this.setUserData(response.data);

        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    setUserData(data) {
      const copyOfData = data;

      for (const property in copyOfData)
        if (!copyOfData[property]) delete copyOfData[property];

      this.people.push({
        text: `${copyOfData.Person.name} ${copyOfData.Person.lastName}`,
        value: copyOfData.id,
      });

      this.form.PersonId = copyOfData.id;

      delete copyOfData.Person;

      Object.assign(this.form, copyOfData);
    },
  },
  computed: {
    userId() {
      return this.$props.dialog ? this.databaseId : this.$route.params.id;
    },
  },
  created() {
    if (this.$props.mod === "update") this.loadUserData();

    this.loadAccessProfiles();
  },
};
</script>

<style>
</style>
