<template>
  <BaseForm
    :title="title"
    :mod="mod"
    :flat="flat"
    :divider="divider"
    :dialog="dialog"
    :loading="loading"
    @save="save"
  >
    <template #content>
      <v-container fluid>

        <v-row>
          <v-col>
            <ExamReferenceSelect
              v-model="form.ExamReferenceId"
              class="required"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <MaskNameField v-model="form.name" class="required" />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="required label-mask">
            <label>Máscara:</label>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <MaskTextField v-model="form.mask" :cleanMask="cleanMask" />
          </v-col>
        </v-row>
      </v-container>

    </template>

    <template #actions></template>
  </BaseForm>
</template>

<script>
import axios from "axios";
import { baseApiUrl } from "@/global";
import formMixin from "@/mixins/formMixin";
import errorHandler from "@/helpers/error_handler";

import BaseForm from "@/components/template/BaseForm";

import MaskNameField from "@/components/template/FormElements/Masks/MaskNameField";
import MaskTextField from "@/components/template/FormElements/Masks/MaskTextField";
import ExamReferenceSelect from "@/components/template/FormElements/Exams/ExamReferenceSelect";

export default {
  components: {
    BaseForm,
    ExamReferenceSelect,
    MaskNameField,
    MaskTextField,
  },
  mixins: [formMixin],
  directives: {},
  computed: {
    examModeMaskId() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      loading: false,
      cleanMask: false,
      examModeOption: {},
      form: {},
    };
  },
  methods: {
    save(actionAfterOperation) {
      
      const errors = this.validateForm();

      if (errors.length) {
        errors.forEach((e) => {
          console.log(`${e}`);
        });
      } else {
        this[this.mod](actionAfterOperation);
      }
    },
    async insert(actionAfterOperation) {
      try {
        this.loading = true;

        const response = await axios.post(
          `${baseApiUrl}/exam-modes-masks`,
          this.form
        );

        this.loading = false;

        console.log("Operação concluída com sucesso");

        this.cleanMask = true;

        this.afterInsertion(actionAfterOperation, {
          ...this.form,
          id: response.data.id,
        });
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async update(actionAfterUpdate) {
      try {
        this.loading = true;

        await axios.put(
          `${baseApiUrl}/exam-modes-masks/${this.examModeMaskId}`,
          this.form
        );
        this.loading = false;

        this.afterUpdate(actionAfterUpdate);
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async loadMaskData() {
      try {
        this.loading = true;

        const response = await axios.get(
          `${baseApiUrl}/exam-modes-masks/${this.examModeMaskId}`
        );

        this.setMaskData(response.data);

      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    setMaskData(data) {
      this.form = { ...data };

      this.loading = false;
    },
    validateForm() {
      const errors = [];

      if (!this.form.ExamReferenceId) errors.push("Campo 'Exame' obrigatório!");

      if (!this.form.name) errors.push("Nome obrigatório!");

      if (!this.form.mask) errors.push("Máscara obrigatória!");

      return errors;
    },
  },
  created() {
    if (this.mod === "update") this.loadMaskData();
  },
};
</script>

<style>
.label-mask {
  font-size: 1.05rem;
}
</style>