export default ({ exam, patient, medicalReport }) => {
  const template = `
  <html>
  <head>
    <title>Laudo</title>
  </head>
  <body>
    <div style="width:470px;border:1px;font-size:11px;font-family: Arial, Helvetica, sans-serif;">
      <p style="text-align:center;font-weight:bold;font-size:14px;">
        Dados do paciente
      </p>
      <table style="width:100%">
        <tr>
          <td>
            <span style="font-weight:bold;">Paciente:</span>
            ${patient.name}
          </td>
          <td style="text-align: right">
            <span style="font-weight:bold;">Exame:</span> ${exam.id}
          </td>
        </tr>
        <tr>
          <td>
            <span style="font-weight:bold;">Data de nascimento:</span>
            ${patient.dateOfBirth}
          </td>
          <td style="text-align:right">
            <span style="font-weight:bold;">Sexo:</span>
            ${patient.gender}
          </td>
        </tr>
        <tr>
          <td>
            <span style="font-weight:bold;">Idade:</span>
            ${patient.age} anos
          </td>
          <td style="text-align:right">
            <span style="font-weight:bold;">Solicitado por:</span> ${exam.requestedBy}
          </td>
        </tr>
        <tr>
          <td>
            <span style="font-weight:bold;">Local:</span>
            ${exam.partnershipBranchName}
          </td>
          <td style="text-align:right">
            <span style="font-weight:bold;">Data de realização:</span>
            ${exam.dateOfRealization}
          </td>
        </tr>
      </table>

      <div style="margin:30px 0px;"></div>
      <p style="text-align:center;font-weight:bold;font-size:14px;">
        ${exam.name}
      </p>
      <div style="margin:30px 0px;"></div>
      ${medicalReport}
    </div>
  </body>
</html>
`;

  return convertUn(0.4, template);
};

function convertUn(factor, str) {
  let copyOfStr = `${str}`;

  copyOfStr = copyOfStr.replace(/:?:\s*\d*px/g, function(value) {
    let qtd = value.replace(/\D/gim, "");
    return `:${Math.round(factor * parseInt(qtd))}px`;
  });

  return copyOfStr;
}
