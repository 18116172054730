<template>
  <div>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>Exames</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="items"
          item-key="id"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:top>
            <v-expansion-panels class="mb-5" :value="0">
              <v-expansion-panel>
                <v-expansion-panel-header> Filtros </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-container fluid>
                    <v-row
                      ><v-col>
                        <v-text-field
                          label="Paciente"
                          placeholder="Pesquise pelo nome/cpf/rg do paciente"
                          v-model="filters.patient"
                        >
                        </v-text-field
                      ></v-col>

                      <v-col
                        ><v-select
                          class="mr-2"
                          v-model="filters.PartnershipId"
                          :items="partnerships"
                          label="Empresa / Órgão conveniado"
                        >
                        </v-select
                      ></v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-text-field
                          label="Data inicial"
                          v-model="filters.startDate"
                          type="date"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="Data final"
                          v-model="filters.endDate"
                          type="date"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <div class="d-flex justify-end align-center flex-wrap">
                      <v-btn color="success" class="mr-2" @click="loadExams">
                        <v-icon left>mdi-filter</v-icon> Filtrar</v-btn
                      >
                      <v-btn @click="clearFilters"
                        ><v-icon left>mdi-eraser</v-icon> Limpar</v-btn
                      >
                    </div>
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-btn class="mt-3 mb-3" color="primary" @click="downloadFiles">
              <v-icon left>mdi-cloud-download</v-icon> BAIXAR EXAMES</v-btn
            >
          </template>

          <template v-slot:[`item.examUrgency`]="{ item }">
            <v-chip
              small
              :color="urgentExamStatusColor(item.UrgentExamStatusId)"
              >{{ item.examUrgency }}</v-chip
            >
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              icon
              title="Ver exame"
              @click="openReportScreen(item.id, item.ExamStatusId)"
            >
              <v-icon> mdi-eye </v-icon>
            </v-btn>
            <v-btn icon title="Baixar exame" @click="downloadFile(item.id)"
              ><v-icon>mdi-cloud-download</v-icon></v-btn
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <div class="text-center mt-2">
      <v-pagination
        v-model="currentPage"
        :length="numberOfPages"
        @input="loadExams"
      ></v-pagination>
    </div>

    <v-dialog max-width="300" persistent v-model="downloadExamsDialog">
      <v-card>
        <v-card-title>Baixando arquivos...</v-card-title>
        <v-card-text>
          <div class="pa-5 d-flex justify-center align-center">
            <v-progress-circular indeterminate></v-progress-circular>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { baseApiUrl } from "@/global";
import errorHandler from "@/helpers/error_handler";
import moment from "moment";
import jsZip from "jszip";
import { saveAs } from "file-saver";

export default {
  components: {},
  data() {
    return {
      currentPage: 1,
      resultsPerPage: 50,
      totalRows: 0,
      search: null,
      loading: false,
      loadingExamModes: false,
      loadingPartnerships: false,
      downloadExamsDialog: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Data do exame", value: "dateOfRealization" },
        { text: "Recebido em", value: "createdAt" },
        { text: "Paciente", value: "patientName" },
        { text: "Modalidade", value: "examMode" },
        { text: "Status", value: "examStatus", sortable: false },
        { text: "Urgência", value: "examUrgency" },
        { text: "Ações", value: "actions", sortable: false },
      ],
      items: [],
      examModes: [],
      partnerships: [],
      filters: {
        ExamReferenceId: null,
        patient: null,
        PartnershipId: null,
        startDate: null,
        endDate: null,
      },
      timer: setInterval(() => {
        this.loadExams();
      }, 60000),
    };
  },
  methods: {
    async loadExams(page) {
      try {
        this.loading = true;

        let currentPage = page ? page : 1;

        const params = {
          pagination: true,
          currentPage: currentPage,
          ExamStatusId: 1,
          ...this.filters,
        };

        let url = `${baseApiUrl}/exams`;

        const response = await axios.get(url, { params });

        this.totalRows = response.data.count ? response.data.count : 0;

        this.setResponseData(response.data.rows);

        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    deleteExamFromDataTable(examId) {
      const index = this.items.findIndex((item) => item.id == examId);

      if (index != -1) {
        let copyOfItems = [...this.items];

        copyOfItems.splice(index, 1);

        this.items = copyOfItems;
      }
    },
    async deleteExam(examId) {
      try {
        if (
          !(await this.$root.$confirm(
            "Atenção",
            "Deseja realmente deletar o exame selecionado?",
            {
              color: "primary",
            }
          ))
        )
          return;

        this.loading = true;

        await axios.delete(`${baseApiUrl}/people/${examId}`);

        this.deleteExamFromDataTable(examId);

        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    openInsertScreen() {
      this.$router.push("/exames/cadastrar");
    },
    openReportScreen(examId, status) {
      if (status === 1)
        this.$router.push(`/medico/exames/${examId}/laudo/cadastrar`);
      else if (status === 2 || status === 3)
        this.$router.push(`/medico/exames/${examId}/laudo/editar`);
    },
    clearFilters() {
      this.filters = {
        ExamReferenceId: null,
        patient: null,
        PartnershipId: null,
        startDate: null,
        endDate: null,
      };
      this.currentPage = 1;
      this.loadExams();
    },
    setResponseData(data) {
      const copyOfData = [...data];

      this.items = copyOfData.map((item) => {
        item.patientName = `${item.Patient.Person.name} ${item.Patient.Person.lastName}`;

        item.examStatus = item.ExamStatus.name;

        item.examUrgency = item.UrgentExamStatus.name;

        item.dateOfRealization = moment(item.dateOfRealization)
          .add("3", "hours")
          .format("DD/MM/YYYY [ás] HH:mm");

        item.createdAt = moment(item.createdAt).format("DD/MM/YYYY [ás] HH:mm");

        return item;
      });
    },
    async loadExamModes() {
      try {
        this.loadingExamModes = true;

        this.examModes = [];

        const url = `${baseApiUrl}/exam-modes`;

        const res = await axios.get(url, {
          params: {
            withExamReferences: true,
          },
        });

        this.setExamModesData(res.data);
        this.loadingExamModes = false;
      } catch (error) {
        this.loadingExamModes = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    setExamModesData(data) {
      const copyOfData = [...data];

      for (const mode of copyOfData) {
        this.examModes.push({
          header: mode.name,
        });

        for (const examReference of mode.ExamReferences) {
          this.examModes.push({
            value: examReference.id,
            text: examReference.name,
          });
        }

        this.examModes.push({
          divider: true,
        });
      }
    },
    async loadPartnerships() {
      try {
        this.loadingExamPartnerships = true;

        const url = `${baseApiUrl}/partnerships`;

        const res = await axios.get(url);

        this.setPartnerships(res.data);

        this.loadingExamPartnerships = false;
      } catch (error) {
        this.loadingExamPartnerships = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    setPartnerships(data) {
      const copyOfData = [...data];

      this.partnerships = copyOfData.map((p) => ({
        text: p.Person.fantasyName,
        value: p.id,
      }));
    },
    urgentExamStatusColor(status) {
      if (status === 1) return "success";
      else if (status === 2) return "warning";
      else if (status === 3) return "error";
    },
    downloadFile(examId) {
      window.open(`${baseApiUrl}/exams/${examId}/download`, "_blank");
    },
    async downloadFiles() {
      try {
        this.downloadExamsDialog = true;

        const zip = new jsZip();
        const exams = [...this.items];

        for (let i = 0; i < exams.length; i++) {
          let response = await axios.get(
            `${baseApiUrl}/exams/${exams[i].id}/download`,
            {
              responseType: "blob",
            }
          );

          var aux = new Blob([response.data]);

          zip.file(`exame-${exams[i].id}.zip`, aux);
        }

        const allFiles = await zip.generateAsync({ type: "blob" });

        saveAs(
          allFiles,
          `exames(${moment().format("YYYY-MM-DD-HH:mm:ss")}).zip`
        );

        this.downloadExamsDialog = false;
      } catch (error) {
        console.log(error);
        this.downloadExamsDialog = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.totalRows / this.resultsPerPage);
    },
  },
  created() {
    this.loadExams();
    this.loadExamModes();
    this.loadPartnerships();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style>
</style>
