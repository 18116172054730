<template>
  <div>
    <v-select
      :loading="loadingExamModes"
      :value="value"
      @change="$emit('input', $event)"
      :items="examModeOptions"
      :item-value="fromExamForm ? 'text' : 'value'"
      item-text="text"
      label="Modalidade:"
      hint="Escolha a modalidade do exame"
    >
    </v-select>
  </div>
</template>

<script>
import { baseApiUrl } from "@/global";
import axios from "axios";

export default {
  props: ["value", "fromExamForm"],
  components: {},
  data() {
    return {
      loadingExamModes: false,
      examModeOptions: [],
    };
  },
  methods: {
    setResponseData(data) {
      this.examModeOptions = data.map((d) => {
        return {
          value: d.id,
          text: d.name,
        };
      });

      this.loadingExamModes = false;
    },
    async loadExamModes() {
      try {
        this.loadingExamModes = true;

        this.examModeOptions = [];

        const url = `${baseApiUrl}/exam-modes`;

        const res = await axios.get(url);

        this.setResponseData(res.data);
      } catch (error) {
        this.loadingExamModes = false;

        this.errorLoadingData(error);
      }
    },
    errorLoadingData(error) {
      // if (Array.isArray(error)) {
      //   error.forEach((e) => {
      //     this.$toasted.global.defaultError({ msg: `${e}` });
      //   });
      // } else {
      //   this.$toasted.global.defaultError({ msg: `${error}` });
      // }
      console.log(error);
    },
  },
  created() {
    this.loadExamModes();
  },
};
</script>

<style>
</style>