import { jsPDF } from "jspdf";
import template from "@/assets/templates/medical_reports/template";
import moment from "moment";

async function genMedicalReportJSPdfObj(examData) {
  let doc = new jsPDF("p", "mm", "a4");

  let docContent = template({
    exam: {
      name: examData.ExamReference ? examData.ExamReference.name : "",
      id: examData.id,
      dateOfRealization: moment(examData.dateOfRealization).format(
        "DD/MM/YYYY"
      ),
      partnershipBranchName: examData.PartnershipBranch.name,
      requestedBy: examData.requestedBy,
    },
    patient: {
      name: `${examData.Patient.Person.name} ${examData.Patient.Person.lastName}`,
      dateOfBirth: moment(examData.Patient.Person.dateOfBirth).format(
        "DD/MM/YYYY"
      ),
      age: Math.floor(
        moment().diff(examData.Patient.Person.dateOfBirth, "years", true)
      ),
      gender:
        examData.Patient.Person.gender === "male" ? "Masculino" : "Feminino",
    },
    medicalReport: examData.MedicalReport.report,
  });

  await doc.html(docContent, {
    x: 10,
    y: 10,
  });

  return doc;
}

export default { genMedicalReportJSPdfObj };
