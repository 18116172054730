<template>
  <div>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>Relatório de Exames</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="items"
          item-key="id"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:top>
            <v-expansion-panels class="mb-5" :value="0">
              <v-expansion-panel>
                <v-expansion-panel-header> Filtros </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-container fluid>
                    <v-row
                      ><v-col>
                        <v-text-field
                          label="Paciente"
                          placeholder="Pesquise pelo nome/cpf/rg do paciente"
                          v-model="filters.patient"
                        >
                        </v-text-field
                      ></v-col>
                      <v-col
                        ><v-select
                          class="mr-2"
                          v-model="filters.ExamReferenceId"
                          :items="examModes"
                          label="Exame"
                        >
                        </v-select
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        ><v-select
                          class="mr-2"
                          v-model="filters.PartnershipId"
                          :items="partnerships"
                          label="Empresa / Órgão conveniado"
                        >
                        </v-select
                      ></v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-text-field
                          label="Data inicial"
                          v-model="filters.startDate"
                          type="date"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="Data final"
                          v-model="filters.endDate"
                          type="date"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <div class="d-flex justify-end align-center flex-wrap">
                      <v-btn
                        color="blue white--text"
                        class="mr-2"
                        @click="generateReports"
                      >
                        <v-icon left>mdi-content-save</v-icon> Gerar e
                        baixar</v-btn
                      >
                      <v-btn color="success" class="mr-2" @click="loadExams">
                        <v-icon left>mdi-creation</v-icon> Gerar</v-btn
                      >
                      <v-btn @click="clearFilters"
                        ><v-icon left>mdi-eraser</v-icon> Limpar</v-btn
                      >
                    </div>
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              icon
              title="Ver exame"
              @click="openReportScreen(item.id, item.ExamStatusId)"
            >
              <v-icon> mdi-eye </v-icon>
            </v-btn>
            <v-btn
              icon
              title="Baixar laudo"
              @click="genMedicalReportPDF(item.id)"
            >
              <v-icon> mdi-file-pdf </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <div class="text-center mt-2">
      <v-pagination
        v-model="currentPage"
        :length="numberOfPages"
        @input="loadExams"
      ></v-pagination>
    </div>

    <v-dialog v-model="loadingDialog" persistent max-width="290">
      <v-card>
        <div class="pa-5 d-flex justify-center align-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { baseApiUrl } from "@/global";
import errorHandler from "@/helpers/error_handler";
import moment from "moment";
import examsHelper from "@/helpers/exams";

import jsZip from "jszip";
import { saveAs } from "file-saver";

export default {
  components: {},
  data() {
    return {
      resultsPerPage: 50,
      totalRows: 0,
      search: null,
      loading: false,
      loadingDialog: false,
      loadingExamModes: false,
      loadingPartnerships: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Data", value: "dateOfRealization" },
        { text: "Paciente", value: "patientName" },
        { text: "Exame", value: "examName", sortable: false },
        { text: "Modalidade", value: "examMode" },
        { text: "Subspecialidade", value: "examSubspecialty", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
      items: [],
      examModes: [],
      partnerships: [],
      currentPage: 1,
      filters: {
        ExamReferenceId: null,
        patient: null,
        PartnershipId: null,
        startDate: null,
        endDate: null,
      },
    };
  },
  methods: {
    async loadExams(page) {
      try {
        this.loading = true;

        let currentPage = page ? page : 1;

        const params = {
          pagination: true,
          currentPage: currentPage,
          ...this.filters,
        };

        let url = `${baseApiUrl}/reports/exams`;

        const response = await axios.get(url, {
          params,
        });

        this.totalRows = response.data.count ? response.data.count : 0;

        this.setResponseData(response.data.rows);

        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },

    async loadExamsForReports() {
      const params = {
        ...this.filters,
      };

      let url = `${baseApiUrl}/reports/exams`;

      const response = await axios.get(url, {
        params,
      });

      return { ...response.data };
    },

    async deleteExam(examId) {
      try {
        if (
          !(await this.$root.$confirm(
            "Atenção",
            "Deseja realmente deletar o exame selecionado?",
            {
              color: "primary",
            }
          ))
        )
          return;

        this.loading = true;

        await axios.delete(`${baseApiUrl}/people/${examId}`);

        this.deleteExamFromDataTable(examId);

        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    openInsertScreen() {
      this.$router.push("/exames/cadastrar");
    },
    openReportScreen(examId, status) {
      if (status === 1)
        this.$router.push(`/medico/exames/${examId}/laudo/cadastrar`);
      else if (status === 2 || status === 3)
        this.$router.push(`/medico/exames/${examId}/laudo/editar`);
    },
    clearFilters() {
      this.filters = {
        ExamReferenceId: null,
        patient: null,
        PartnershipId: null,
        startDate: null,
        endDate: null,
      };
    },
    setResponseData(data) {
      const copyOfData = [...data];

      this.items = copyOfData.map((item) => {
        item.patientName = `${item.Patient.Person.name} ${item.Patient.Person.lastName}`;

        item.examName = item.ExamReference.name;

        item.examMode = item.ExamReference.ExamMode.name;

        item.examSubspecialty = item.ExamReference.ExamSubspecialty.name;

        item.dateOfRealization = moment(item.dateOfRealization).format(
          "DD/MM/YYYY [ás] HH:mm"
        );

        return item;
      });
    },
    async loadExam(examId) {
      const url = `${baseApiUrl}/exams/${examId}`;

      const res = await axios.get(url);

      return { ...res.data };
    },
    async loadExamModes() {
      try {
        this.loadingExamModes = true;

        this.examModes = [];

        const url = `${baseApiUrl}/exam-modes`;

        const res = await axios.get(url, {
          params: {
            withExamReferences: true,
          },
        });

        this.setExamModesData(res.data);
        this.loadingExamModes = false;
      } catch (error) {
        this.loadingExamModes = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    setExamModesData(data) {
      const copyOfData = [...data];

      for (const mode of copyOfData) {
        this.examModes.push({
          header: mode.name,
        });

        for (const examReference of mode.ExamReferences) {
          this.examModes.push({
            value: examReference.id,
            text: examReference.name,
          });
        }

        this.examModes.push({
          divider: true,
        });
      }
    },
    urgentExamStatusColor(status) {
      if (status === 1) return "success";
      else if (status === 2) return "warning";
      else if (status === 3) return "error";
    },

    async loadPartnerships() {
      try {
        this.loadingExamPartnerships = true;

        const url = `${baseApiUrl}/partnerships`;

        const res = await axios.get(url);

        this.setPartnerships(res.data);

        this.loadingExamPartnerships = false;
      } catch (error) {
        this.loadingExamPartnerships = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    setPartnerships(data) {
      const copyOfData = [...data];

      this.partnerships = copyOfData.map((p) => ({
        text: p.Person.fantasyName,
        value: p.id,
      }));
    },

    async genMedicalReportPDF(examId) {
      try {
        this.loadingDialog = true;
        const examData = await this.loadExam(examId);

        const medicalReportJSPdfObj = await examsHelper.genMedicalReportJSPdfObj(
          examData
        );

        medicalReportJSPdfObj.save("laudo.pdf");
        this.loadingDialog = false;
      } catch (error) {
        this.loadingDialog = false;
        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },

    async generateReports() {
      try {
        this.loadingDialog = true;
        const data = await this.loadExamsForReports();

        const zip = new jsZip();

        const csvHeader =
          '"CÓDIGO","DATA","EXAME","PACIENTE","EMPRESA / ÓRGÃO CONVENIADO","LOCAL","VALOR (R$)"';

        const CSVArray = [csvHeader];

        for (const exam of data.exams) {
          let dateOfRealization = moment(exam.dateOfRealization).format(
            "DD/MM/YYYY [ás] HH:mm"
          );
          let patientName = `${exam.Patient.Person.name} ${exam.Patient.Person.lastName}`;
          let partnershipName =
            exam.PartnershipBranch.Partnership.Person.fantasyName;
          let partnershipBranchName = exam.PartnershipBranch.name;
          let value = parseFloat(exam.price).toFixed(2);

          //Gerando CSV
          CSVArray.push(
            `"${exam.id}","${dateOfRealization}","${exam.ExamReference.name}","${patientName}","${partnershipName}","${partnershipBranchName}","${value}"`
          );

          //Gerando PDF
          const doc = await examsHelper.genMedicalReportJSPdfObj(exam);

          zip.file(
            `exame-${exam.id}(Paciente: ${patientName}).pdf`,
            doc.output("blob")
          );
        }

        CSVArray.push("");
        CSVArray.push("");
        CSVArray.push(
          `"","","","","","VALOR TOTAL: ","${parseFloat(data.amount).toFixed(
            2
          )}"`
        );

        const CSV = "\ufeff" + CSVArray.join("\n");

        const csvFile = new Blob([CSV], { type: "text/csv;charset=UTF-8" });

        zip.file(`relatorio-${moment().format("DD-MM-YYYY")}.csv`, csvFile);

        const report = await zip.generateAsync({ type: "blob" });

        this.loadingDialog = false;

        saveAs(report, "reports.zip");
      } catch (error) {
        this.loadingDialog = false;
        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.totalRows / this.resultsPerPage);
    },
  },
  mounted() {
    this.loadExamModes();
    this.loadPartnerships();
  },
};
</script>

<style>
</style>
