<template>
  <BaseForm
    :title="title"
    :mod="mod"
    :flat="flat"
    :divider="divider"
    :dialog="dialog"
    :loading="loading"
    @save="save"
  >
    <template #content>
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="6"><h4>Exames</h4></v-col>
          <v-col cols="12" sm="2"><h4>Preços</h4></v-col>
        </v-row>
        <v-row v-bind:key="ref.id" v-for="ref in examReferences">
          <v-col cols="12" sm="6" class="mt-2">
            <p>{{ ref.name }}</p>
          </v-col>
          <v-col cols="12" sm="2">
            <PriceField :label="'Preço'" v-model="ref.price" />
          </v-col>
        </v-row>
        <v-row></v-row>
      </v-container>
    </template>
    <template #actions></template>
  </BaseForm>
</template>

<script>
import axios from "axios";
import { baseApiUrl } from "@/global";
import formMixin from "@/mixins/formMixin";
import errorHandler from "@/helpers/error_handler";
import BaseForm from "@/components/template/BaseForm";

import PriceField from "@/components/template/FormElements/Exams/PriceField";

export default {
  components: {
    BaseForm,
    PriceField,
  },
  data() {
    return {
      resultsPerPage: 50,
      totalRows: 0,
      loading: false,
      relatedExamReference: [],
      examReferences: [],
    };
  },
  mixins: [formMixin],
  computed: {
    partnershipId() {
      return this.$route.params.partnershipId;
    },
  },
  methods: {
    setPrices(data) {
      const copyOfData = [...data];

      this.relatedExamReference = copyOfData.map((d) => {
        d.price = parseFloat(d.ExamPartnership.price);

        return d;
      });

      for (let i = 0; i < this.relatedExamReference.length; i++) {
        for (const r of this.examReferences) {
          if (r.id === this.relatedExamReference[i].id) {
            r.price = this.relatedExamReference[i].price;
          }
        }
      }

      this.loading = false;
    },
    async loadPrices() {
      try {
        this.loading = true;

        let url = `${baseApiUrl}/exam-partnerships/${this.partnershipId}`;

        const response = await axios.get(url);

        this.setPrices(response.data.ExamReferences);
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async setExamReferenceData(data) {
      this.examReferences = data.map((d) => {
        return {
          id: d.id,
          name: d.name,
          price: 0,
        };
      });

      await this.loadPrices();

      this.loadingExamReferences = false;
    },
    async loadExamReferences() {
      try {
        this.loadingExamReferences = true;

        this.examReferences = [];

        const url = `${baseApiUrl}/exam-references?pagination=0`;

        const res = await axios.get(url);

        this.setExamReferenceData(res.data);
      } catch (error) {
        this.loadingExamReferences = false;

        this.errorLoadingData(error);
      }
    },
    save(actionAfterOperation) {
      const dataToSend = this.selectFilled();

      this[this.mod](actionAfterOperation, dataToSend);
    },
    async update(actionAfterUpdate, dataToSend) {
      try {
        this.loading = true;

        console.log(dataToSend);

        await axios.put(
          `${baseApiUrl}/exam-partnerships/${this.partnershipId}`,
          dataToSend
        );

        this.loading = false;

        this.afterUpdate(actionAfterUpdate);
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    selectFilled() {
      let filledPriceData = [];

      for (const ref of this.examReferences) {
        if (ref.price != 0) {
          ref.price = parseFloat(ref.price)
          filledPriceData.push(ref);
        } 
      }

      return filledPriceData;
    },
  },
  mounted() {
    this.loadExamReferences();
  },
};
</script>

<style>
</style>