<template>
  <div>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>Administrar máscaras</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn small rounded color="success" @click="openInsertScreen">
          Cadastrar <v-icon right dark> mdi-plus </v-icon></v-btn
        >
      </v-toolbar>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="items"
          item-key="id"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:top>
            <div class="d-flex justify-end align-center flex-wrap">
              <v-text-field
                v-model="filters.search"
                class="mr-2"
                clearable
                placeholder="Pesquisar"
              ></v-text-field>
              <ExamReferenceSelect
                v-model="filters.ExamReferenceId"
              />
              <v-btn title="Filtrar" icon @click="loadMasks()">
                <v-icon> mdi-filter </v-icon>
              </v-btn>
              <v-btn
                title="Limpar filtros"
                icon
                class="ml-2"
                @click="clearFilters()"
              >
                <v-icon> mdi-eraser </v-icon>
              </v-btn>
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn icon title="Editar" @click="openUpdateScreen(item.id)">
              <v-icon> mdi-pencil </v-icon>
            </v-btn>

            <v-btn icon title="Excluir" @click="deleteMask(item.id)">
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <div class="text-center mt-2">
      <v-pagination
        v-model="filters.currentPage"
        :length="numberOfPages"
        @change="loadMasks"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { baseApiUrl } from "@/global";
import axios from "axios";
import ExamReferenceSelect from "@/components/template/FormElements/Exams/ExamReferenceSelect";
import errorHandler from "@/helpers/error_handler";

export default {
  components: {
    ExamReferenceSelect,
  },
  data() {
    return {
      resultsPerPage: 50,
      totalRows: 0,
      loading: false,
      headers: [
        { text: "Nome", value: "name", sortable: true },
        { text: "Exame", value: "ExamReference.name" },
        {
          text: "Modalidade",
          value: "examModeName",
          sortable: false,
        },
        { text: "Ações", value: "actions", sortable: false },
      ],
      items: [],
      examModeOptions: [],
      subspecialtyOptions: [],
      filters: {
        search: null,
        ExamReferenceId: null,
        currentPage: 1,
      },
    };
  },
  methods: {
    setResponseData(data) {
      const copyOfData = [...data];

      this.items = copyOfData.map((item) => {
        item.examModeName = item.ExamReference.ExamMode.name;

        return item;
      });

      this.loading = false;
    },
    async loadMasks(page) {
      try {
        this.loading = true;

        let currentPage = page ? page : 1;

        let url = `${baseApiUrl}/exam-modes-masks`;

        const response = await axios.get(url, {
          params: {
            pagination: true,
            search: this.filters.search,
            examReference: this.filters.ExamReferenceId,
            currentPage: currentPage,
          },
        });

        this.totalRows = response.data.count ? response.data.count : 0;

        this.setResponseData(response.data.rows);
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async confirmDialog(message) {
      return await this.$root.$confirm("Atenção", message, {
        color: "primary",
      });
    },
    deleteMaskInArray(maskId) {
      const index = this.items.findIndex((item) => item.id === maskId);

      if (index !== -1) this.items.splice(index, 1);
    },
    async deleteMask(maskId) {
      try {
        if (
          !(await this.confirmDialog(
            "Deseja realmente deletar a máscara selecionada?"
          ))
        )
          return;

        this.loading = true;

        await axios.delete(`${baseApiUrl}/exam-modes-masks/${maskId}`);

        this.deleteMaskInArray(maskId);

        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    clearFilters() {
      this.filters = {
        search: null,
        ExamReferenceId: null,
        currentPage: 1,
      };

      this.loadMasks();
    },
    openInsertScreen() {
      this.$router.push("/mascaras/cadastrar");
    },
    openUpdateScreen(maskId) {
      this.$router.push(`/mascaras/${maskId}`);
    },
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.totalRows / this.resultsPerPage);
    },
  },
  mounted() {
    this.loadMasks();
  },
};
</script>

<style>
</style>