<template>
  <BaseForm
    :title="title"
    :mod="mod"
    :flat="flat"
    :divider="divider"
    :dialog="dialog"
    :loading="loading"
  >
    <template #content>
      <v-container v-if="!loading">
        <v-row>
          <v-col cols="12" md="8">
            <v-row>
              <v-col>
                <h2>
                  {{ examTitle }}
                </h2>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <div class="show-archives">
                  <v-list-item
                    v-bind:key="item.id"
                    v-for="item in examData.ExamFiles"
                  >
                    <v-list-item-icon>
                      <v-icon> mdi-image </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-btn text @click="setImageShow(item.fileName)">{{
                          item.fileName.split("-")[1]
                        }}</v-btn>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <div
                  class="d-flex flex-column justify-space-between align-center"
                >
                  <v-alert
                    v-if="!srcShowImage"
                    border="right"
                    color="blue-grey"
                    dark
                  >
                    Clique em um acima para visualizar
                  </v-alert>

                  <v-slider
                    v-if="srcShowImage"
                    v-model="width"
                    class="align-self-stretch"
                    min="200"
                    max="500"
                    step="1"
                  ></v-slider>

                  <v-img
                    v-if="srcShowImage"
                    :aspect-ratio="1"
                    :width="width"
                    :src="srcShowImage"
                  ></v-img>
                </div>
              </v-col>
            </v-row>

            <v-row class="mb-2">
              <v-col>
                <v-btn small text @click="downloadFiles">
                  <v-icon left> mdi-download</v-icon>
                  Baixar arquivos como zip
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="4">
            <v-row>
              <v-col>
                <h2>Dados do paciente</h2>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                >Nome: {{ examData.Patient.Person.name }}
                {{ examData.Patient.Person.lastName }}</v-col
              ></v-row
            >
            <v-row>
              <v-col>Sexo: {{ patientGender }}</v-col></v-row
            >
            <v-row>
              <v-col
                >Data de Nascimento: {{ patientDateOfBirth }} -
                {{ patientAge }} anos</v-col
              ></v-row
            >
            <v-row
              ><v-col
                >Profissão: {{ examData.Patient.Person.occupation }}</v-col
              ></v-row
            >
            <v-row>
              <v-col
                >Empresa: {{ examData.Patient.Person.company }}</v-col
              ></v-row
            >
            <v-row
              ><v-col
                >CPF: {{ examData.Patient.Person.taxDocument }}</v-col
              ></v-row
            >
            <v-row
              ><v-col>RG: {{ examData.Patient.Person.rg }}</v-col></v-row
            >
            <v-row
              ><v-col>Urgente: {{ examUrgency }}</v-col></v-row
            >
            <v-row
              ><v-col>Data do Exame: {{ examDate }}</v-col></v-row
            >
            <v-row>
              <v-col>Dados clinícos: {{ examData.clinicalData }}</v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="mt-2">
          <v-col><h3>Exame</h3></v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-select
              v-model="form.ExamReferenceId"
              :items="examModes"
              label="Selecione um exame"
              return-object
              item-text="text"
              item-value="value"
              @change="loadMasks"
            >
            </v-select>
          </v-col>
        </v-row>

        <v-row class="mt-2">
          <v-col><h3>Máscaras de laudo</h3></v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="masks">
            <v-checkbox
              :key="mask.id"
              v-for="mask in masks"
              hide-details
              v-model="selectedMasks"
              :value="mask.id"
              :label="mask.name"
              class="mask"
              @change="addOrRemoveMask(mask, $event)"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row class="mt-5">
          <v-col><h3>Dados clínicos</h3></v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-textarea
              solo
              rows="3"
              clear-icon="mdi-close-circle"
              v-model="form.clinicalData"
              counter="255"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row class="mt-2">
          <v-col>
            <h3>Laudo</h3>
          </v-col>
        </v-row>

        <v-row class="mt-2 mb-2">
          <v-col>
            <v-card class="pa-2"
              ><ckeditor v-model="form.report" :config="editorConfig"></ckeditor
            ></v-card>
          </v-col>
        </v-row>

        <v-row class="mt-2">
          <v-col><h3>Observações</h3></v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-textarea
              solo
              rows="3"
              clear-icon="mdi-close-circle"
              v-model="form.observations"
              counter="255"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template #actions v-if="!loading">
      <v-spacer></v-spacer>
      <v-btn @click="$router.go(-1)">
        <v-icon left> mdi-close</v-icon>
        Cancelar
      </v-btn>
      <v-btn
        color="warning"
        class="ml-2"
        v-if="examData.ExamStatusId === 1"
        @click="requestResend"
      >
        <v-icon left> mdi-refresh </v-icon>
        Solicitar reenvio
      </v-btn>
      <v-btn color="success" class="ml-2" @click="save">
        <v-icon left> mdi-check</v-icon>
        Salvar
      </v-btn>
    </template>

  </BaseForm>
</template>

<script>
import axios from "axios";
import { baseApiUrl } from "@/global";
import errorHandler from "@/helpers/error_handler";
import BaseForm from "@/components/template/BaseForm";
import formMixin from "@/mixins/formMixin";
import moment from "moment";
import CKEditor from "ckeditor4-vue";

export default {
  components: {
    BaseForm,
    ckeditor: CKEditor.component,
  },
  mixins: [formMixin],
  data() {
    return {
      editorData: "",
      srcShowImage: null,
      editorConfig: {
        extraAllowedContent: "div(*)[*]",
        disableNativeSpellChecker: false,
        removeButtons:
          "ImageButton,HiddenField,Flash,Smiley,Iframe,About,Source,Anchor",
        extraPlugins: ["justify", "font"],
      },
      loading: false,
      loadingExamModes: false,
      loadingMasks: false,
      width: 300,
      examData: null,
      examModes: [],
      selectedExamMode: null,
      selectedMasks: [],
      editorOptions: {
        language_url:
          "https://dyonir.github.io/vue-tinymce-editor//static/langs/pt_BR.js",
      },
      masks: [],
      form: {
        report: "",
      },
    };
  },
  methods: {
    downloadFiles() {
      const examId = this.$route.params.examId;
      window.open(`${baseApiUrl}/exams/${examId}/download`, '_blank');
    },
    setImageShow(fileName) {
      this.srcShowImage = `${baseApiUrl}/uploads/${fileName}`;
    },
    save() {
      const errors = this.validateForm();

      if (errors.length) {
        this.$root.$errorDialog(errors, {
          width: "800",
          color: "primary",
        });
      } else {
        this[this.$props.mod]();
      }
    },
    async insert() {
      try {
        if (
          !(await this.$root.$confirm(
            "Atenção",
            "Deseja confirmar as alterações no laudo?",
            {
              color: "primary",
            }
          ))
        )
          return;

        this.loading = true;

        const examId = this.$route.params.examId;

        const url = `${baseApiUrl}/exams/${examId}/medical-report`;

        const dataToInsert = this.prepareDataToInsert(this.form);

        const response = await axios.post(url, dataToInsert);

        this.loading = false;

        this.afterInsertion("back", { ...this.form, ...response.data }, {});
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async update() {
      try {
        this.loading = true;

        const examId = this.$route.params.examId;

        const url = `${baseApiUrl}/exams/${examId}/medical-report`;

        const dataToUpdate = this.prepareDataToUpdate(this.form);

        await axios.put(url, dataToUpdate);

        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    prepareDataToUpdate(data) {
      const copyOfData = { ...data };

      copyOfData.ExamReferenceId = copyOfData.ExamReferenceId.value;

      return copyOfData;
    },
    async requestResend() {
      try {
        if (
          !(await this.$root.$confirm(
            "Atenção",
            "Deseja realmente solicitar o reenvio do exame?",
            {
              color: "primary",
            }
          ))
        )
          return;

        this.loading = true;

        const examId = this.$route.params.examId;

        const url = `${baseApiUrl}/exams/${examId}/status`;

        await axios.put(url, { ExamStatusId: 3 });

        this.loading = false;

        this.$router.go(-1);
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    prepareDataToInsert(data) {
      const copyOfData = { ...data };

      copyOfData.ExamReferenceId = copyOfData.ExamReferenceId.value;

      return copyOfData;
    },
    validateForm() {
      const errors = [];

      if (!this.form.ExamReferenceId) errors.push("Selecione o EXAME");

      if (!this.form.report || !this.form.report.length)
        errors.push("Nehum conteúdo para o laudo foi informado");

      return errors;
    },
    removeMask(maskId) {
      const reportContent = this.stringToHTML(this.form.report);

      const masks = reportContent.getElementsByClassName("mask-content");

      for (let i = 0; i < masks.length; i++) {
        console.log(masks[i].getAttribute("data-id"));
        if (masks[i].getAttribute("data-id") === `mask-${maskId}`) {
          masks[i].remove();
        }
      }

      this.form.report = reportContent.outerHTML;
    },
    addMask(maskData) {
      this.form.report += `<div data-id="mask-${maskData.id}" class="mask-content">${maskData.mask}</div>`;
    },
    addOrRemoveMask(maskData, selecteds) {
      const copyOfMaskData = { ...maskData };

      if (selecteds.findIndex((i) => i === maskData.id) !== -1) {
        this.addMask(copyOfMaskData);
      } else this.removeMask(copyOfMaskData.id);
    },
    supportDOMParser() {
      if (!window.DOMParser) return false;
      var parser = new DOMParser();
      try {
        parser.parseFromString("x", "text/html");
      } catch (err) {
        return false;
      }
      return true;
    },
    stringToHTML(str) {
      if (this.supportDOMParser()) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(str, "text/html");
        return doc.body;
      }

      const dom = document.createElement("div");
      dom.innerHTML = str;
      return dom;
    },

    async loadExamModes() {
      try {
        this.loadingExamModes = true;

        this.examModes = [];

        const url = `${baseApiUrl}/exam-modes`;

        const res = await axios.get(url, {
          params: {
            withExamReferences: true,
          },
        });

        this.setExamModesData(res.data);
        this.loadingExamModes = false;
      } catch (error) {
        this.loadingExamModes = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    setExamModesData(data) {
      const copyOfData = [...data];

      for (const mode of copyOfData) {
        this.examModes.push({
          header: mode.name,
        });

        for (const examReference of mode.ExamReferences) {
          this.examModes.push({
            value: examReference.id,
            text: examReference.name,
          });
        }

        this.examModes.push({
          divider: true,
        });
      }
    },
    async loadMasks(selected) {
      try {
        if (!selected) return;

        this.loadingMasks = true;
        const response = await axios.get(
          `${baseApiUrl}/exam-modes-masks?examReference=${selected.value}`
        );

        this.masks = { ...response.data };
        this.loadingMasks = false;
      } catch (error) {
        this.loadingMasks = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async loadExamData() {
      try {
        const examId = this.$route.params.examId;

        this.loading = true;
        const response = await axios.get(`${baseApiUrl}/exams/${examId}`);

        console.log(response.data);

        this.examData = { ...response.data };
        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async loadMedicalReportData() {
      try {
        const examId = this.$route.params.examId;

        this.loading = true;
        const response = await axios.get(
          `${baseApiUrl}/exams/${examId}/medical-report`
        );

        this.setMedicalReportData(response.data);
        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    setMedicalReportData(data) {
      const copyOfData = { ...data };
      console.log(data);

      const { Exam: examData, ...medicalReportData } = copyOfData;

      this.examData = examData;
      this.form = medicalReportData;
      this.form.ExamReferenceId = {
        text: examData.ExamReference.name,
        value: examData.ExamReferenceId,
      };
    },
  },
  computed: {
    medicalReportId() {
      return this.$props.dialog ? this.databaseId : this.$route.params.id;
    },
    patientGender() {
      return this.examData.Patient.Person.gender === "male"
        ? "Masculino"
        : "Feminino";
    },
    patientAge() {
      return Math.floor(
        moment().diff(this.examData.Patient.Person.dateOfBirth, "years", true)
      );
    },
    patientDateOfBirth() {
      return moment(this.examData.Patient.Person.dateOfBirth).format(
        "DD/MM/YYYY"
      );
    },
    examDate() {
      return moment(this.examData.dateOfRealization).format(
        "DD/MM/YYYY [ás] HH:mm"
      );
    },
    examUrgency() {
      return this.examData.urgent ? "Sim" : "Não";
    },
    examTitle() {
      return this.examData.examMode;
    },
  },
  created() {
    this.loadExamModes();

    if (this.$props.mod === "insert") this.loadExamData();
    else if (this.$props.mod === "update") this.loadMedicalReportData();
  },
  beforeDestroy() {
    this.examModes = [];
    this.selectedExamMode = null;
    this.selectedMasks = [];
    this.examData = null;
    this.form = {
      report: "",
    };
  },
};
</script>


<style scoped>
.masks {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.mask {
  display: inline;
  margin-left: 20px;
}

.show-archives {
  height: 300px;
  overflow-y: scroll;
}
</style>